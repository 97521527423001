import { graphql } from "gatsby"
import React from "react"
import BlockWysiwyg from "../components/common/blockWysiwyg/blockWysiwyg"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import HeroBanner from "../components/common/heroBanner/heroBanner"
import { getCarouselImageOG } from "../components/common/functions"

export default function BasicPage({
  data: { nodeBasicPage: page },
  pageContext,
}) {
  const {
    title,
    field_content: content,
    field_seo,
    relationships: { field_hero: heroItems },
  } = page

  const intl = useIntl()

  const carouselFiltered = heroItems.filter(
    hero => hero.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} />
      {carouselFiltered && <HeroBanner hero={carouselFiltered} extraClass=" page-cms-hero" isHeightSmall={1} />}
      {content && <BlockWysiwyg content={content.value} />}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeBasicPage(id: { eq: $id }) {
      ...basicPageFields
    }
  }
`
