import React from "react"
import ReactPlayer from "react-player/wistia"

const VideoBackground = props => (
  <div className="video-background">
    <div className="video-foreground">
      <ReactPlayer
        url={props.video}
        className="video-iframe"
        config={{
          wistia: {
            options: {
              autoPlay: "muted",
              endVideoBehavior: "loop",
              fitStrategy: "contain",
              volume: 0,
              videoFoam: true,
              volumeControl: false,
              playButton: false,
            },
          },
        }}
      />
    </div>
  </div>
)

export default VideoBackground
