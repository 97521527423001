import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import Heading from "../heading"
import VideoBackground from "../videoBackground"
import "./heroBanner.scss"
import HTMLReactParser from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroBanner = props => {
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    lazyLoad: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const hotelClass = props.isHotel ? " hotel-content" : ""
  const extraClass = props.extraClass ? props.extraClass : ""
  const heightSmall = props.isHeightSmall ? "height-small" : ""
  const lists = props.hero

  // Get Mobile
  const window1 = typeof window !== "undefined" ? window : ""

  const [width, setWidth] = useState(window1.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window1.innerWidth)
  }
  useEffect(() => {
    window1.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window1.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  let isMobile = width <= 640

  const bannerItems = lists.reduce((list, current, index) => {
    const bannerText = (
      <Container className={"hero-contents" + hotelClass}>
        <Row className="justify-content-center alignCenter">
          <Col md={10}>
            <Heading
              caption={current.field_caption}
              titleHeadingSize={2}
              title={
                current.title &&
                HTMLReactParser(
                  current.title
                    .toString()
                    .replace(/&/g, "<em className='speCarac'>&</em>")
                )
              }
              fontStyle={1}
              description={
                current.field_subtitle &&
                HTMLReactParser(
                  current.field_subtitle
                    .toString()
                    .replace(/&/g, "<em className='speCarac'>&</em>")
                )
              }
            />
          </Col>
        </Row>
      </Container>
    )

    let backgroundImage = "", imageMob = "", imageAlt = ""


    if (
      current.relationships.media.relationships.thumbnail &&
      current.relationships.media.relationships.thumbnail.localFile
        .childImageSharp.fluid
    ) {
      // Wistia thumbnail
      backgroundImage =
        current.relationships.media.relationships.thumbnail.localFile
          .childImageSharp.fluid.srcWebp
    } else if (current.relationships.media.relationships.field_image) {
      // Image
      backgroundImage =
        current.relationships.media.relationships.field_image.localFile
          .childImageSharp.fluid.srcWebp

      imageMob = current.relationships.media.relationships.field_image.localFile.childImageSharp
      imageAlt = current.relationships.media.field_image.alt
    }

    const styleBg = !isMobile ? "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), url(" + backgroundImage : "none";

    if (current.relationships.media.internal.type === "media__wistia") {
      list.push(
        <div className="hero-element video">
          <div
            className="heroBanner-infos"
            style={{
              backgroundImage:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), url(" +
                backgroundImage +
                ")",
            }}
          >
            {bannerText}
            <VideoBackground
              video={current.relationships.media.field_media_oembed_wistia}
              type={current.relationships.media.internal.type}
            />
          </div>
        </div>
      )
    } else {
      list.push(
        <div>
          <div
            className="hero-element"
            style={{
              backgroundImage:
                styleBg +
                ")",
            }}
          >
            {isMobile ? (
              <div className="mobImage">
                <GatsbyImage image={getImage(imageMob)} alt={imageAlt} />
              </div>
            ) : (
              <>
              </>
            )}
            {props.extraClass === " page-cms-hero" && <img className="wideBanner" src={backgroundImage} alt={imageAlt} />}
            {bannerText}
          </div>
        </div>
      )
    }

    return list
  }, [])

  return (
    <section className={"hero custom-dots " + heightSmall + extraClass}>
      <Slider {...settings}>{bannerItems}</Slider>
      <span className="scrollMouse"></span>
    </section>
  )
}

export const query = graphql`
  fragment getCarouselItems on paragraph__hero {
    title
    field_caption
    field_subtitle
    relationships {
      media {
        ... on media__image {
          field_image {
            alt
          }
          internal {
            type
          }
          relationships {
            ...getHeroImage
          }
        }
        ... on media__wistia {
          internal {
            type
          }
          field_media_oembed_wistia
          relationships {
            thumbnail {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
      field_cover_image {
        relationships {
          field_image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default HeroBanner
