import { graphql } from "gatsby"
import HTMLReactParser from "html-react-parser"
import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./blockWysiwyg.scss"

const BlockWysiwyg = props => {
  const generic = props.isGeneric ? "generic" : ""

  useEffect(() => {
    var acc = document.getElementsByTagName("dt")
    var i
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active")

        var panel = this.nextElementSibling

        if (panel.style.display === "block") {
          panel.style.display = "none"
        } else {
          panel.style.display = "block"
        }
      })
    }
  })

  return (
    <section className={"blk-wysiwig " + generic}>
      <Container>
        <Row>
          <Col>
            <div className="content-text">

              {
                generic
                ?
                  HTMLReactParser(
                      props.content
                          .toString()
                          .replace(/&/g, "<em className='speCarac'>&</em>")
                  )
                :
                HTMLReactParser(props.content)
              }
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment wysiwyg on paragraph__wysiwyg {
    field_text {
      value
    }
    internal {
      type
    }
  }
`
export default BlockWysiwyg
